import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";
import { Box } from "@mui/material";
import DeleteAccountPic from "../assets/DeleteAccount.png";
import DeleteAccountPicOTD from "../assets/DeleteAccountOTD.png";

const DeleteAccount = () => {
  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="sm">
        <Box textAlign="center">
          <Typography variant="h5" gutterBottom>
            How to Delete Your Account
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mb: 2, mt: 5 }}>
          On Plug, to delete your account, press the "permanent actions" button on the
          Profile screen and then press the "Delete" button.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          This will delete your account, plugs you created and kept in your
          history, templates you created, and groups you created.
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          On OTD by Plug, to delete your account, press the "Delete Account" button on the
          Profile screen and then press the "Delete" button.
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          (You can also email hello@pluggo.app for assistance with deleting some
          or all of your data)
        </Typography>

        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Plug App
          </Typography>
          <img
            src={DeleteAccountPic}
            alt="Delete account screen on Plug mobile app"
            style={{ width: "100%", maxWidth: "500px" }}
          />
        </Box>

        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            OTD by Plug
          </Typography>
          <img
            src={DeleteAccountPicOTD}
            alt="Delete account screen on OTD mobile app"
            style={{ width: "100%", maxWidth: "500px" }}
          />
        </Box>
      </Container>
    </FullScreenBox>
  );
};

export default DeleteAccount;
