import { Routes, Route } from "react-router-dom";
import ClarifyHome from "./pages/ClarifyHome";
import SummaryDetail from "./pages/SummaryDetail";
import AdminPage from './pages/AdminPage';

function ClarifyApp() {
  return (
    <Routes>
      <Route path="/" element={<ClarifyHome />} />
      <Route path="/summary/:id" element={<SummaryDetail />} />
      <Route path="/adminonly" element={<AdminPage />} />
    </Routes>
  );
}

export default ClarifyApp;