import { collection, getDocs, doc, getDoc, orderBy, query } from 'firebase/firestore';
import { FIREBASE_DB } from '../../firebase';

export interface ClarifySummary {
  id: string;
  title: string;
  sourceUrl: string;
  summary: string;
  sourceDate: string;
  visualizationUrl?: string;
  additionalImageUrl?: string;  // This will be both the image and its source
  sections: {
    title: string;
    points: string[];
  }[];
}

export const getSummaries = async () => {
  const summariesRef = collection(FIREBASE_DB, 'clarify-summaries');
  const q = query(summariesRef, orderBy('sourceDate', 'desc'));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as ClarifySummary[];
};

export const getSummaryById = async (id: string) => {
  const docRef = doc(FIREBASE_DB, 'clarify-summaries', id);
  const docSnap = await getDoc(docRef);
  return {
    id: docSnap.id,
    ...docSnap.data()
  } as ClarifySummary;
};

export {}; 