import { useState, useEffect } from 'react';
import { Box, Container, TextField, Button, Typography, Alert, CircularProgress } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { FIREBASE_DB } from '../../firebase';
import { ClarifySummary } from '../services/clarifySummaryService';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ADMIN_UID = "19179520941";

function AdminPage() {
  const [jsonInput, setJsonInput] = useState('');
  const [status, setStatus] = useState<{type: 'success' | 'error', message: string} | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (user && user.uid !== ADMIN_UID) {
          setStatus({ 
            type: 'error', 
            message: 'Access denied. You do not have administrator privileges.' 
          });
          setTimeout(() => {
            navigate('/clarify');
          }, 3000); // Redirect after 3 seconds
          return;
        }
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [user, navigate]);

  const handleSubmit = async () => {
    try {
      const data = JSON.parse(jsonInput) as Omit<ClarifySummary, 'id'>;
      const docRef = await addDoc(collection(FIREBASE_DB, 'clarify-summaries'), data);
      setStatus({ type: 'success', message: `Document added with ID: ${docRef.id}` });
      setJsonInput('');
    } catch (error) {
      setStatus({ type: 'error', message: `Error: ${error instanceof Error ? error.message : 'Unknown error'}` });
    }
  };

  if (loading) {
    return (
      <Container sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '100vh'
      }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!user || user.uid !== ADMIN_UID) {
    return (
      <Container sx={{ py: 4 }}>
        {status && (
          <Alert severity={status.type} sx={{ mt: 2 }}>
            {status.message}
          </Alert>
        )}
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin - Add New Summary
      </Typography>
      
      <Typography variant="body2" sx={{ mb: 2 }}>
        Paste your JSON object with the following structure:
      </Typography>
      
      <Box 
        component="pre" 
        sx={{ 
          backgroundColor: 'background.paper',
          p: 2,
          borderRadius: 1,
          mb: 3,
          overflow: 'auto'
        }}
      >
{`{
  "title": "string",
  "sourceUrl": "string",
  "summary": "string",
  "sourceDate": "string",
  "visualizationUrl": "string (optional)",
  "additionalImageUrl": "string (optional)",
  "sections": [
    {
      "title": "string",
      "points": ["string", "string", ...]
    },
    ...
  ]
}`}
      </Box>

      <TextField
        multiline
        rows={10}
        fullWidth
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        placeholder="Paste your JSON here..."
        sx={{ mb: 2 }}
      />

      <Button 
        variant="contained" 
        onClick={handleSubmit}
        sx={{ mb: 2 }}
      >
        Add to Database
      </Button>

      {status && (
        <Alert severity={status.type} sx={{ mt: 2 }}>
          {status.message}
        </Alert>
      )}
    </Container>
  );
}

export default AdminPage; 