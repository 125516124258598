import { useEffect, useState } from 'react';
import Container from "@mui/material/Container";
import { Box, Typography, Grid, Card, CardContent, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FullScreenBox from '../../components/FullScreenBox';
import { ClarifySummary, getSummaries } from '../services/clarifySummaryService';
import youtubeIcon from '../../assets/youtube-logo.svg';
import plugLogo from '../../assets/PlugLogo.png';

function ClarifyHome() {
  const [summaries, setSummaries] = useState<ClarifySummary[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSummaries = async () => {
      try {
        console.log('Fetching summaries...');
        const data = await getSummaries();
        console.log('Fetched data:', data);
        setSummaries(data);
      } catch (error) {
        console.error('Error fetching summaries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSummaries();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <FullScreenBox>
      <Container 
        component="main" 
        maxWidth={false} 
        sx={{ 
          padding: 4,
          maxWidth: '1800px' // Custom max width
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          mb: 4
        }}>
          <img 
            src={plugLogo} 
            alt="Plug Logo" 
            style={{ 
              height: '40px',
              width: 'auto'
            }} 
          />
          <Typography 
            variant="h2" 
            sx={{ 
              fontWeight: 700,
              letterSpacing: '-0.5px',
              background: 'linear-gradient(45deg, #F5F5F7 30%, rgba(255, 255, 255, 0.8) 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Clarify
          </Typography>
        </Box>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          No views, just summaries
        </Typography>
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'block',
            mb: 4,
            color: 'text.secondary',
            fontStyle: 'italic'
          }}
        >
          Note: These summaries are AI-generated and may contain inaccuracies
        </Typography>
        <Grid container spacing={3}>
          {summaries.map((summary) => (
            <Grid item xs={12} sm={6} md={4} key={summary.id}>
              <Card 
                sx={{ 
                  height: '100%',
                  border: '2px solid rgba(255, 255, 255, 0.1)',
                  backgroundColor: 'transparent',  // Removed background color
                  borderRadius: 2,
                  position: 'relative',
                  zIndex: 1,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': { 
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.4)',
                    border: '2px solid rgba(255, 255, 255, 0.2)',
                  }
                }}
              >
                <RouterLink 
                  to={`/clarify/summary/${summary.id}`}
                  style={{ 
                    textDecoration: 'none',
                    display: 'block',
                    height: '100%',
                  }}
                >
                  <CardContent sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: 3,
                    position: 'relative',
                    zIndex: 2
                  }}>
                    <Typography 
                      variant="h6" 
                      gutterBottom
                      sx={{ 
                        fontWeight: 600,
                        color: 'primary.main',
                        mb: 2
                      }}
                    >
                      {summary.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ 
                        mb: 'auto',  // Changed to push content to top
                        lineHeight: 1.6
                      }}
                    >
                      {summary.summary}
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                      pt: 1,
                      borderTop: '1px solid rgba(255, 255, 255, 0.1)'
                    }}>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ fontWeight: 500 }}
                      >
                        {summary.sourceDate}
                      </Typography>
                      <Link 
                        href={summary.sourceUrl}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': { opacity: 0.8 }
                        }}
                      >
                        <img 
                          src={youtubeIcon} 
                          alt="YouTube" 
                          style={{ 
                            height: '24px',
                            width: 'auto'
                          }} 
                        />
                      </Link>
                    </Box>
                  </CardContent>
                </RouterLink>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </FullScreenBox>
  );
}

export default ClarifyHome; 