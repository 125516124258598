import { useEffect, useState } from 'react';
import { Box, Typography, Paper, List, ListItem, Container, Link, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FullScreenBox from "../../components/FullScreenBox";
import { ClarifySummary, getSummaryById } from '../services/clarifySummaryService';

function SummaryDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [summary, setSummary] = useState<ClarifySummary | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSummary = async () => {
      if (!id) return;
      try {
        const data = await getSummaryById(id);
        setSummary(data);
      } catch (error) {
        console.error('Error fetching summary:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!summary) {
    return <Typography>Summary not found</Typography>;
  }

  return (
    <FullScreenBox>
      <Container maxWidth={false} sx={{ padding: 4, maxWidth: '1800px' }}>
        <IconButton 
          onClick={() => navigate('/clarify')}
          sx={{ mb: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        
        <Typography variant="h3" gutterBottom>
          {summary.title}
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 4 }}>
          <Link 
            href={summary.sourceUrl}
            target="_blank"
            sx={{ 
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            View on YouTube
          </Link>
          <Typography variant="body2" color="text.secondary">
            {summary.sourceDate}
          </Typography>
        </Box>
        
        {summary.visualizationUrl && (
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
            <img 
              src={summary.visualizationUrl} 
              alt={`Visualization for ${summary.title}`}
              style={{ 
                maxWidth: '100%',
                height: 'auto'
              }} 
            />
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          {summary.sections.map((section) => (
            <Paper 
              key={section.title} 
              sx={{ 
                p: 3, 
                mb: 3,
                backgroundColor: 'background.paper',
                boxShadow: 2
              }}
            >
              <Typography 
                variant="h5" 
                gutterBottom 
                color="primary"
                sx={{ fontWeight: 'medium' }}
              >
                {section.title}
              </Typography>
              <List>
                {section.points.map((point, index) => (
                  <ListItem 
                    key={index}
                    sx={{ 
                      pl: 2,
                      py: 0.5,
                      '&::before': {
                        content: '"•"',
                        position: 'absolute',
                        left: 0,
                        color: 'primary.main'
                      },
                      display: 'flex',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography sx={{ display: 'inline' }}>{point}</Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
          ))}
        </Box>

        {summary.additionalImageUrl && (
          <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img 
              src={summary.additionalImageUrl} 
              alt="Additional visualization"
              style={{ 
                maxWidth: '100%',
                height: 'auto'
              }} 
            />
            <Link 
              href={summary.additionalImageUrl}
              target="_blank"
              sx={{ 
                color: 'hyperlink.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                mt: 1,
                fontSize: '0.875rem'
              }}
            >
              Image Source
            </Link>
          </Box>
        )}
      </Container>
    </FullScreenBox>
  );
}

export default SummaryDetail; 