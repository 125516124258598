import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import appStoreBadge from "../assets/app-store-badge.png";
import playStoreBadge from "../assets/play-store-badge.png";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";

const LandingPage = () => {
  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="sm">
        <Typography 
          variant="h1" 
          component="h1" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            letterSpacing: '-0.5px',
            background: 'linear-gradient(45deg, #F5F5F7 30%, rgba(255, 255, 255, 0.8) 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: { xs: '3rem', sm: '4rem' },  // Responsive font size
            mb: 2
          }}
        >
          Plug
        </Typography>
        <Typography 
          variant="h5" 
          component="h2" 
          gutterBottom
          sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            fontWeight: 500
          }}
        >
          Plan. Manage. Connect.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, color: 'rgba(255, 255, 255, 0.7)' }}>
          No spam, promo, ads, unnecessary notifications, selling data, or
          in-app purchases.
          <br />
          <br />
          Just plans with friends, enjoy.
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          justifyContent: 'center', 
          alignItems: 'center',
          gap: 2 
        }}>
          <a
            href="https://apps.apple.com/us/app/plug/id1473681218"
            target="_blank"
            rel="noopener noreferrer"
            style={{ 
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={appStoreBadge}
              alt="Download on the App Store"
              style={{ 
                width: '250px',
                height: 'auto'
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.pluggo.plugapp"
            target="_blank"
            rel="noopener noreferrer"
            style={{ 
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={playStoreBadge}
              alt="Get it on Google Play"
              style={{ 
                width: '250px',
                height: 'auto'
              }}
            />
          </a>
        </Box>
      </Container>
    </FullScreenBox>
  );
};

export default LandingPage;
