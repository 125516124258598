import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import appStoreBadge from "../assets/app-store-badge.png";
import playStoreBadge from "../assets/play-store-badge.png";
import plugLogo from "../assets/PlugLogo.png";
import FullScreenBox from "../components/FullScreenBox";
import { Alert } from "@mui/material";

const LandingPageOTD = () => {
  return (
    <FullScreenBox>
      <Container component="main" maxWidth="sm">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          pt: 4
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 3,
            mb: 2
          }}>
            <img 
              src={plugLogo} 
              alt="Plug Logo" 
              style={{ 
                height: '60px',
                width: 'auto'
              }} 
            />
            <Typography 
              variant="h1" 
              component="h1" 
              sx={{ 
                fontWeight: 700,
                letterSpacing: '-0.5px',
                background: 'linear-gradient(45deg, #F5F5F7 30%, rgba(255, 255, 255, 0.8) 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: { xs: '3rem', sm: '4rem' },
                textAlign: 'center'
              }}
            >
              OTD by Plug
            </Typography>
          </Box>

          <Typography 
            variant="h5" 
            component="h2" 
            gutterBottom
            sx={{ 
              color: 'rgba(255, 255, 255, 0.9)',
              fontWeight: 500,
              mb: 4,
              textAlign: 'center'
            }}
          >
            Recommend, Rank, Reign
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, color: 'rgba(255, 255, 255, 0.7)' }}>
            Share your song of the day (OTD) based on themes you set - like Cards Against Humanity, but with music. Music against humanity?
            Submit your best (or worst) song that fits the theme, rank everyone's picks, and claim your musical superiority.
          </Typography>

          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                mb: 2,
                fontWeight: 600
              }}
            >
              HOW IT WORKS:
            </Typography>
            <Typography 
              component="ul" 
              sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                pl: 2,
                '& li': { mb: 1 }
              }}
            >
              <li>Recommend a song (based on theme)</li>
              <li>Rank the submissions</li>
              <li>Reign supreme!</li>
            </Typography>
          </Box>

          <Typography 
            variant="body1" 
            sx={{ 
              mb: 2, 
              color: 'rgba(255, 255, 255, 0.7)',
              fontStyle: 'italic'
            }}
          >
            Just an MVP, if it's cool, many updates to come.
          </Typography>

          <Typography 
            variant="body2" 
            sx={{ 
              mb: 4, 
              color: 'rgba(255, 255, 255, 0.6)',
              fontStyle: 'italic',
              fontSize: '0.875rem'
            }}
          >
            Note: Being too good at this may put strain on your friendships. Maybe just let them win a round or two?
          </Typography>

          <Alert 
            severity="info" 
            sx={{ 
              mb: 3, 
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            Available now on iOS and Android!
          </Alert>

          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'center', 
            alignItems: 'center',
            gap: 2
          }}>
            <a
              href="https://apps.apple.com/us/app/otd-by-plug/id6741745627"
              target="_blank"
              rel="noopener noreferrer"
              style={{ 
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img
                src={appStoreBadge}
                alt="Download on the App Store"
                style={{ 
                  width: '250px',
                  height: 'auto'
                }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=app.pluggo.otd"
              target="_blank"
              rel="noopener noreferrer"
              style={{ 
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img
                src={playStoreBadge}
                alt="Get it on Google Play"
                style={{ 
                  width: '250px',
                  height: 'auto'
                }}
              />
            </a>
          </Box>
        </Box>
      </Container>
    </FullScreenBox>
  );
};

export default LandingPageOTD;
